export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11')
];

export const server_loads = [];

export const dictionary = {
		"/": [4],
		"/activism/[group=activismgroup]": [5],
		"/cms": [~6],
		"/cms/dashboard": [7,[2]],
		"/cms/dashboard/[group=activismgroup]": [~8,[2]],
		"/cms/dashboard/[group=activismgroup]/article/[article_key]": [~9,[2]],
		"/cms/dashboard/[group=activismgroup]/new-article": [10,[2]],
		"/udohr": [11,[3]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';